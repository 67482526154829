<template>
  <div>
    <h3 class="d-flex justify-content-between">
      <span> Valor em Caixa </span>
      <span> {{ checkout.total | formatCurrency }} </span>
    </h3>
    <small class="text-muted">
      Valor de abertura + Entradas - Saídas + Alugueis e Produtos em dinheiro
    </small>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/numbers'
import { calculateCheckoutTotal } from '@/helpers/checkouts'
import { PAYMENT_METHODS_IDS } from '@/constants'

export default {
  name: 'CheckoutTotal',
  filters: {
    formatCurrency
  },
  props: {
    checkout: {
      type: Object,
      required: true
    }
  },

  computed: {
    total() {
      return calculateCheckoutTotal(this.checkout, true, [
        PAYMENT_METHODS_IDS.CASH
      ])
    }
  }
}
</script>
