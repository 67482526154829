const formatCurrency = (amount) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(amount || 0)

const parseCurrency = (amount) =>
  amount
    ? parseFloat(amount?.toString()?.replaceAll('.', '')?.replaceAll(',', '.'))
    : amount

export { formatCurrency, parseCurrency }
