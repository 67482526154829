<template>
  <b-form>
    <b-form-group label="Nome" label-for="name">
      <b-form-input id="name" v-model="form.name" :state="validation.name" />
      <b-form-invalid-feedback v-if="$v.form.name.$invalid">
        Informe o nome da operação
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Valor" label-for="amount" :class="validationClass">
      <b-input-group prepend="R$">
        <b-form-input
          id="amount"
          v-model="form.amount"
          v-money="{
            decimal: ',',
            thousands: '.',
            precision: 2
          }"
          :state="validation.amount"
        />
      </b-input-group>
      <b-form-invalid-feedback v-if="$v.form.amount.$invalid">
        Informe o valor da operação
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import withFormValidation from '@/mixins/withFormValidation'
import { required } from 'vuelidate/lib/validators'
import { formatCurrency, parseCurrency } from '@/helpers/numbers'

export default {
  name: 'OperationForm',
  filters: { formatCurrency },
  mixins: [
    withFormValidation({
      fields: ['name', 'amount']
    })
  ],
  validations: {
    form: {
      name: { required },
      amount: {
        required: (value) => parseCurrency(value) > 0
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    validationClass() {
      const { $dirty, $invalid } = this.$v.form.amount

      return {
        'is-invalid': $dirty && $invalid
      }
    }
  },
  watch: {
    form: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },
  destroyed() {
    this.form = {
      name: '',
      type: null,
      amount: 0
    }
  }
}
</script>
