<template>
  <b-form novalidate @submit.prevent="handleSubmit">
    <b-modal
      v-model="open"
      :title="title"
      @ok.prevent="onOk"
      @cancel="onCancel"
      @close="onCancel"
    >
      <OperationForm ref="form" v-model="form" @submit="onSubmit" />
    </b-modal>
  </b-form>
</template>

<script>
import { OPERATION_TYPES } from '@/constants'
import { parseOperation } from '@/helpers/checkouts'
import OperationForm from './OperationForm'

export default {
  name: 'CheckoutModal',
  components: {
    OperationForm
  },
  props: {
    value: {
      type: Boolean
    },
    type: {
      type: String,
      required: true
    },
    checkout: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        amount: 0,
        type: null,
        name: ''
      }
    }
  },
  computed: {
    open: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    isRevenue() {
      return this.type === OPERATION_TYPES.REVENUE
    },
    isExpense() {
      return this.type === OPERATION_TYPES.EXPENSE
    },
    title() {
      return this.isRevenue ? 'Nova Entrada' : 'Nova Saída'
    }
  },
  watch: {
    checkout(value) {
      this.form = value
    }
  },
  created() {
    this.form.type = this.type
  },
  methods: {
    onOk() {
      this.$refs.form.handleSubmit()
    },
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      const parsedOperation = parseOperation(this.form)
      this.$emit('ok', parsedOperation)
    }
  }
}
</script>
