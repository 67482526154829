<template>
  <div>
    <BaseCheckoutCardGroup :title="rentTitle">
      <b-card header="Dinheiro" header-class="text-bold" class="text-center">
        {{ checkout.report.rents.cash | formatCurrency }}
      </b-card>
      <b-card header="Crédito" header-class="text-bold" class="text-center">
        {{ checkout.report.rents.creditCard | formatCurrency }}
      </b-card>
      <b-card header="Débito" header-class="text-bold" class="text-center">
        {{ checkout.report.rents.debitCard | formatCurrency }}
      </b-card>
      <b-card header="PIX" header-class="text-bold" class="text-center">
        {{ checkout.report.rents.pix | formatCurrency }}
      </b-card>
    </BaseCheckoutCardGroup>

    <BaseCheckoutCardGroup :title="productTitle">
      <b-card header="Dinheiro" header-class="text-bold" class="text-center">
        {{ checkout.report.products.cash | formatCurrency }}
      </b-card>
      <b-card header="Crédito" header-class="text-bold" class="text-center">
        {{ checkout.report.products.creditCard | formatCurrency }}
      </b-card>
      <b-card header="Débito" header-class="text-bold" class="text-center">
        {{ checkout.report.products.debitCard | formatCurrency }}
      </b-card>
      <b-card header="PIX" header-class="text-bold" class="text-center">
        {{ checkout.report.products.pix | formatCurrency }}
      </b-card>
    </BaseCheckoutCardGroup>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/numbers'

import BaseCheckoutCardGroup from './BaseCheckoutCardGroup'
// import StateRenderer from '@/components/StateRenderer'

export default {
  name: 'CheckoutRentsCardGroup',
  filters: {
    formatCurrency
  },
  components: {
    BaseCheckoutCardGroup
    // StateRenderer
  },
  props: {
    checkout: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      default: ''
    }
  },
  computed: {
    rentTotal() {
      return this.calculateTotal(this.checkout.report.rents)
    },
    productTotal() {
      return this.calculateTotal(this.checkout.report.products)
    },
    rentTitle() {
      return `Aluguéis - Total: ${formatCurrency(this.rentTotal)}`
    },
    productTitle() {
      return `Produtos - Total: ${formatCurrency(this.productTotal)}`
    }
  },
  methods: {
    calculateTotal(group) {
      const total = Object.keys(group).reduce((result, current) => {
        return (result += group[current])
      }, 0)

      return total
    }
  }
}
</script>
