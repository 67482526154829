<template>
  <div>
    <CheckoutsList
      :checkouts="checkouts"
      :readonly="readonly"
      :hide-warnings="hideWarnings"
      @refresh="$emit('refresh')"
    >
      <template #actions="{ onOpenCheckout }">
        <slot name="actions" :on-open-checkout="onOpenCheckout" />
      </template>
    </CheckoutsList>
    <template v-if="checkout">
      <CheckoutTotal :checkout="checkout" />
      <hr />
      <CheckoutTotalOperations :checkout="checkout" />
      <hr />
      <CheckoutCashFlowCardGroup
        :checkout="checkout"
        :readonly="readonly"
        @refresh="$emit('refresh')"
      />
      <CheckoutRentsCardGroup :checkout="checkout" />
    </template>
  </div>
</template>

<script>
import CheckoutsList from '@/components/Checkouts/CheckoutsList'
import CheckoutTotal from '@/components/Checkouts/CheckoutTotal'
import CheckoutCashFlowCardGroup from './CheckoutCashFlowCardGroup'
import CheckoutRentsCardGroup from './CheckoutRentsCardGroup'
import CheckoutTotalOperations from './CheckoutTotalOperations'

export default {
  name: 'CheckoutDetails',
  components: {
    CheckoutsList,
    CheckoutTotal,
    CheckoutCashFlowCardGroup,
    CheckoutRentsCardGroup,
    CheckoutTotalOperations
  },
  props: {
    checkouts: {
      type: Object,
      required: true
    },
    checkout: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean
    },
    hideWarnings: {
      type: Boolean
    }
  }
}
</script>
