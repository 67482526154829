<template>
  <!-- <StateRenderer v-bind="checkoutReport">
    <template #loading>
      <content-placeholders-heading />
    </template>d
    <template #default="{ data }">
      
    </template>
  </StateRenderer> -->
  <BaseCheckoutCardGroup title="Fluxo de Caixa">
    <b-card header-class="text-bold">
      <div slot="header" class="d-flex justify-content-between">
        Entradas

        <b-button
          variant="link"
          class="p-0 m-0"
          @click="openViewOperationsModal(OPERATION_TYPES.REVENUE)"
        >
          <small>ver todas</small>
        </b-button>
      </div>
      <h2>{{ checkout.report.revenue | formatCurrency }}</h2>
      <b-button
        v-if="canAddOperation"
        variant="success"
        size="sm"
        class="mr-2"
        @click="openAddOperationModal(OPERATION_TYPES.REVENUE)"
      >
        <span class="fas fa-sign-in-alt mr-1" /> Nova Entrada</b-button
      >
    </b-card>

    <b-card header-class="text-bold">
      <div slot="header" class="d-flex justify-content-between">
        Saídas

        <b-button
          variant="link"
          class="p-0 m-0"
          @click="openViewOperationsModal(OPERATION_TYPES.EXPENSE)"
        >
          <small>ver todas</small>
        </b-button>
      </div>
      <h2>{{ checkout.report.expense | formatCurrency }}</h2>
      <b-button
        v-if="canAddOperation"
        variant="warning"
        size="sm"
        @click="openAddOperationModal(OPERATION_TYPES.EXPENSE)"
      >
        <span class="fas fa-sign-out-alt mr-1" /> Nova Saída</b-button
      >
    </b-card>
    <AddOperationModal
      v-if="addOperationModalType"
      v-model="isAddOperationModalOpen"
      :checkout="checkout"
      :type="addOperationModalType"
      @ok="onAddOperation"
      @cancel="closeAddOperationModal"
    />
    <OperationsModal
      v-model="isOperationsModalOpen"
      :type="operationsModalTypes"
      :checkout="checkout"
      :date="date"
      @cancel="closeViewOperationsModal"
    />
  </BaseCheckoutCardGroup>
</template>

<script>
import { OPERATION_TYPES } from '@/constants'
import { formatCurrency } from '@/helpers/numbers'
import BaseCheckoutCardGroup from './BaseCheckoutCardGroup'
// import StateRenderer from '../StateRenderer'
import AddOperationModal from './AddOperationModal'
import OperationsModal from './OperationsModal'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'

export default {
  name: 'CheckoutCashFlowCardGroup',
  components: {
    BaseCheckoutCardGroup,
    // StateRenderer,
    AddOperationModal,
    OperationsModal
  },
  filters: {
    formatCurrency
  },
  mixins: [
    withAsyncAction({
      key: 'operationAdded',
      fetcher: {
        methodName: 'createOperation',
        handler: services.operations.createOperation
      }
    })
  ],
  props: {
    checkout: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean
    }
  },
  data() {
    return {
      isOperationsModalOpen: false,
      isAddOperationModalOpen: false,
      operationsModalTypes: [],
      addOperationModalType: null
    }
  },
  computed: {
    canAddOperation() {
      return !this.readonly && !this.checkout.closedAt
    }
  },
  created() {
    this.OPERATION_TYPES = OPERATION_TYPES
  },
  methods: {
    openAddOperationModal(operationType) {
      this.addOperationModalType = operationType
      this.$nextTick(() => (this.isAddOperationModalOpen = true))
    },
    closeAddOperationModal() {
      this.addOperationModalType = null
    },
    openViewOperationsModal(operationType) {
      this.operationsModalTypes = [operationType]
      this.$nextTick(() => (this.isOperationsModalOpen = true))
    },
    closeViewOperationsModal() {
      this.operationsModalTypes = []
    },
    async onAddOperation(operation) {
      await this.createOperation({
        ...operation,
        checkoutId: this.checkout.id
      })

      this.closeAddOperationModal()
      this.$root.$toast.success('Operação realizada com sucesso')
      this.$emit('refresh')
    }
  }
}
</script>
