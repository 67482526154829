<template>
  <div>
    <h3>{{ title }}</h3>

    <b-card-group class="mb-3">
      <slot />
    </b-card-group>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckoutCardGroup',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
