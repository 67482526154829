<template>
  <div>
    <h3 class="d-flex justify-content-between">
      <span> Total de Movimentações </span>
      <span> {{ total | formatCurrency }} </span>
    </h3>
    <small class="text-muted">
      Entradas - Saídas + Total de Aluguéis e Produtos (todos os meios de
      pagamento)
    </small>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/numbers'
import { calculateCheckoutTotal } from '@/helpers/checkouts'

export default {
  name: 'CheckoutTotalOperations',
  filters: {
    formatCurrency
  },
  props: {
    checkout: {
      type: Object,
      required: true
    }
  },

  computed: {
    total() {
      return calculateCheckoutTotal(this.checkout)
    }
  }
}
</script>
